import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IntroComponent } from './intro/intro.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { MapPopupComponent } from './map-popup/map-popup.component';
// import { ProfileComponent } from './dashboard/profile/profile.component';


const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'intro', component: IntroComponent },
  { path: 'auth', loadChildren: () => import('./auths/auths.module').then(m => m.AuthModule) },
  { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: 'login', loadChildren: () => import('./auths/auths.module').then(m => m.AuthModule) },
  // { path: 'profile', component: ProfileComponent },
  { path: '**', component: PageNotFoundComponent },
  { path: 'map-popup',component:MapPopupComponent},

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
