import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WeatherForecastService } from 'src/app/services/weather_forecast/weather-forecast.service';
@Component({
  selector: 'app-special-bulletin-flood',
  templateUrl: './special-bulletin-flood.component.html',
  styleUrls: ['./special-bulletin-flood.component.css']
})
export class SpecialBulletinFloodComponent {
  user_info = <any>{};
  myDate;
  myDistrict;
  districtList = [];

  constructor(
    private router:Router, 
    private weatherForecastService:WeatherForecastService) {
    this.userLoginCheck();
  }

  ngOnInit(): void {

    let mydate = new Date();
    let myYear = mydate.getFullYear();
    let myMonth= mydate.getMonth() + 1;
    let myday  = mydate.getDate();
    let myMonthNew = ""; let myDayNew = "";
    if(myMonth < 10) { myMonthNew = "0" + myMonth; }
    if(myday < 10) { myDayNew = "0" + myday; }

    console.log(myYear + "-" + myMonthNew + "-" + myDayNew);
    // this.fetchAlertDistrict(21);
    this.fetchAlertDistrict(myYear + "-" + myMonthNew + "-" + myDayNew);

  }

  dateChangeEvent(e:any) {
    this.fetchAlertDistrict(e);
  }


  async fetchAlertDistrict(param) {
    
    await this.weatherForecastService.getHeatwaveDistrictList(param).subscribe(response=>{
      console.log(response.result);
      this.districtList = response.result;
    });
  }

  // async fetchAlertDistrict(param) {
  //   await this.weatherForecastService.getDistrictAlert(param).subscribe(response=>{
  //     this.districtList = response.result;
  //     console.log(this.districtList);
  //   }, error=> {
  //     console.log(error);
  //   });
  // }

  submitForm() {
    if(this.myDate == '' || this.myDate == null || this.myDate == undefined) {
      alert("Date field can not be empty!");
      return;
    }
    if(this.myDistrict == '' || this.myDistrict == null || this.myDistrict == undefined) {
      alert("District field can not be empty!");
      return;
    }
    if (this.myDate != '' && this.myDistrict != '') {
      var form = document.createElement("form");
      form.target = "view";
      form.method = "POST";
      form.action = "https://api.all.bdservers.site/nlas/report/heat_wave_bulletin.php";
  
      var input_date = document.createElement('input');
      input_date.type = 'hidden';
      input_date.name = 'input_date';
      input_date.value = this.myDate;
      form.appendChild(input_date);
  
      var input_district = document.createElement('input');
      input_district.type = 'hidden';
      input_district.name = 'input_district';
      input_district.value = this.myDistrict;
      form.appendChild(input_district);
  
      document.body.appendChild(form);
      form.submit();
      window.open('', 'view');
    }
  }

  submitFormBn() {
    if(this.myDate == '' || this.myDate == null || this.myDate == undefined) {
      alert("Date field can not be empty!");
      return;
    }
    if(this.myDistrict == '' || this.myDistrict == null || this.myDistrict == undefined) {
      alert("District field can not be empty!");
      return;
    }
    if (this.myDate != '' && this.myDistrict != '') {
      var form = document.createElement("form");
      form.target = "view";
      form.method = "POST";
      form.action = "https://api.all.bdservers.site/nlas/report/heat_wave_bulletin_bn.php";
  
      var input_date = document.createElement('input');
      input_date.type = 'hidden';
      input_date.name = 'input_date';
      input_date.value = this.myDate;
      form.appendChild(input_date);
  
      var input_district = document.createElement('input');
      input_district.type = 'hidden';
      input_district.name = 'input_district';
      input_district.value = this.myDistrict;
      form.appendChild(input_district);
  
      document.body.appendChild(form);
      form.submit();
      window.open('', 'view');
    }
  }

  userLoginCheck() {
    if (!localStorage.getItem('user_info')) {
      this.router.navigate(["/auth"]);
    } else {
      this.user_info = JSON.parse(localStorage.getItem('user_info'));
    }
  }
}
